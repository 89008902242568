import React, { useState } from 'react';

import { yupResolver } from '@hookform/resolvers/yup';
import { useAppState, useAsyncCallback } from '@jaksmok/lovelanguage-common';
import { Box, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useForm } from 'react-hook-form';
import { Link, useHistory, useLocation } from 'react-router-dom';

import Alert from '../../components/Alert/LLAlert';
import Button from '../../components/Buttons/LLButton';
import Input from '../../components/Input/Input';
import Password from '../../components/Input/Password';
import LoginPageLayout from '../../components/Layout/LoginPageLayout';
import UserService from '../../services/user';
import { signInSchema } from '../../utils/formSchema';
import clsx from 'clsx';
import Colors from 'Color';

const useStyles = makeStyles((theme) => ({
  root: {
    '& .MuiSvgIcon-root': {
      fill: 'white',
    },
    '& .MuiInputBase-root': {
      color: 'white',
      borderRadius: '8px',
      background: 'none',
      fontFamily: 'campton-medium',
    },
    '& .MuiInputBase-input': {
      '&::placeholder': {
        color: 'white',
      },
    },
    '&. .MuiOutlinedInput-root': {
      border: 'none',
    },
    '& .MuiOutlinedInput-notchedOutline': {
      border: 'none',
      background: 'rgba(255, 255, 255, 0.05)',
    },
    '& .MuiFormHelperText-root': {
      fontFamily: 'campton-book',
    },
  },
  linkLabel: {
    fontWeight: '600',
    fontSize: '15px',
    textDecoration: 'none',
  },
  footerTextWrapper: {
    display: 'flex',
    justifyContent: 'center',
    gap: '5px',
    marginTop: theme.spacing(4),
  },
  password: {
    paddingRight: 0,
  },
  passwordAdornment: {
    position: 'absolute',
    right: 3,
  },
  signInButton: {
    borderRadius: 8,
    padding: 10,
    background: Colors.primary,
    fontFamily: 'campton-medium',
    fontSize: 16,
    fontWeight: 600,
    '&:hover': {
      background: Colors.primary,
      opacity: 0.5,
    },
  },
  footerLabel: {
    color: 'white',
    fontWeight: 400,
    fontFamily: 'campton-medium',
  },
  forgotPasswordLink: {
    color: Colors.turquoise,
  },
  signUpLink: {
    color: Colors.green,
  },
}));

export default function SignInForm() {
  const classes = useStyles();
  const { control, handleSubmit, formState } = useForm({ resolver: yupResolver(signInSchema) });
  const { signIn, isAuthReady } = useAppState();
  const history = useHistory();
  const location = useLocation();
  const [authError, setAuthError] = useState(null);

  const isAuthEnabled = Boolean(process.env.REACT_APP_SET_AUTH);

  const [handleSignIn] = useAsyncCallback(async ({ email, password }) => {
    setAuthError(null);
    try {
      await signIn(email, password);
      await UserService.verifyProfile();
      history.replace(location?.state?.from || { pathname: '/' });
    } catch (error) {
      const errorCode = error.code;
      let errorMessage = error.message;
      switch (errorCode) {
        case 'auth/wrong-password':
        case 'auth/user-not-found':
          errorMessage = 'Incorrect email address or password.';
          break;
        case 'auth/user-disabled':
          errorMessage = 'Your account has been disabled.';
          break;
        case 'auth/user-not-approved':
          errorMessage = 'Your account has been rejected.';
          break;
        case 'auth/user-pending':
          errorMessage = 'Please wait for account approval.';
          break;
        case 'auth/too-many-requests':
          errorMessage =
            'Thank you! Your account is awaiting approval by techs@360live.app. Have you verified your email? Please check your inbox or junk folder.';
          break;
        default:
          break;
      }
      setAuthError({ ...error, message: errorMessage });
    }
  }, []);

  if (!isAuthEnabled) {
    history.replace('/');
  }

  if (!isAuthReady) {
    return null;
  }

  return (
    <>
      <LoginPageLayout
        fullScreen
        title="Sign into Interpreter Portal"
        footer={
          <div className={classes.footerTextWrapper}>
            <Box component="span" fontSize="16px" className={classes.footerLabel}>
              Want to join us as an interpreter ?
            </Box>
            <Link to="/sign-up" className={clsx(classes.signUpLink, classes.linkLabel)}>
              Sign up
            </Link>
          </div>
        }
      >
        <form onSubmit={handleSubmit(handleSignIn)}>
          <Grid container className={classes.root}>
            <Box item component={Grid} paddingY={2} xs={12}>
              {authError && (
                <Box mb={2}>
                  <Alert type="error" style={{ height: 'auto', width: '100%', borderRadius: 8 }}>
                    {authError.message}
                  </Alert>
                </Box>
              )}

              <Input
                fullWidth
                name="email"
                type="email"
                control={control}
                defaultValue=""
                variant="outlined"
                error={!!formState.errors?.email}
                placeholder="Email address"
                helperText={formState.errors?.email?.message || null}
                className={classes.root}
                classes={{
                  root: classes.input,
                }}
              />
            </Box>

            <Box item component={Grid} paddingY={1} xs={12}>
              <Password
                fullWidth
                name="password"
                type="password"
                control={control}
                defaultValue=""
                variant="outlined"
                error={!!formState.errors?.password}
                placeholder="Password"
                helperText={formState.errors?.password?.message || null}
                InputProps={{
                  className: clsx(classes.password, classes.root),
                }}
                adornmentProps={{ className: classes.passwordAdornment }}
              />
            </Box>

            <Grid item xs={12}>
              <Box width="100%" display="flex" justifyContent="flex-end" mb={2}>
                <Link to="/forget-password" className={clsx(classes.forgotPasswordLink, classes.linkLabel)}>
                  Forgot password?
                </Link>
              </Box>

              <Button
                fullWidth
                variant="contained"
                color="primary"
                type="submit"
                loading={formState.isSubmitting}
                disabled={formState.isSubmitting}
                className={classes.signInButton}
              >
                Sign in
              </Button>
            </Grid>
          </Grid>
        </form>
      </LoginPageLayout>
    </>
  );
}

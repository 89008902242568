import React from 'react';

import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import Colors from 'Color';

const titleStyles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  title: {
    textAlign: 'center',
    color: Colors.green,
  },
});

export const LLDialogTitle = withStyles(titleStyles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <DialogTitle className={classes.root} {...other}>
      <Typography variant="h6" className={classes.title}>
        {children}
      </Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
});

export const DialogWrapper = withStyles((_theme) => ({
  paper: {
    width: '433px',
    height: '560px',
  },
}))(Dialog);

export default function LLDialog({ children, open, handleClose, title, ...props }) {
  return (
    <DialogWrapper open={open} aria-labelledby="ll-dialog-title" onClose={handleClose} {...props}>
      <LLDialogTitle id="ll-dialog-title" onClose={handleClose}>
        {title}
      </LLDialogTitle>
      <DialogContent>{children}</DialogContent>
    </DialogWrapper>
  );
}

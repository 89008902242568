export function getInitials(name) {
  return name
    .split(' ')
    .map((text) => text[0])
    .join('')
    .toUpperCase();
}

export const styledBy = (property, mapping) => (props) => mapping[props[property]];

export const blobUrlToFile = async (blobUrl, fileName) => {
  if (!blobUrl) return;

  const req = await fetch(blobUrl);
  const blob = await req.blob();

  return new File([blob], fileName);
};

export const isBlobUrl = (object) => {
  if (!object) return;

  return object.includes('blob:');
};

export function secondsToTime(secs) {
  const hours = Math.floor(secs / (60 * 60));

  const divisor_for_minutes = secs % (60 * 60);
  const minutes = Math.floor(divisor_for_minutes / 60);

  const divisor_for_seconds = divisor_for_minutes % 60;
  const seconds = Math.ceil(divisor_for_seconds);

  if (!hours) {
    return `${minutes}m ${seconds}s`;
  }

  if (!seconds) {
    return `${seconds}s`;
  }

  return `${hours}h ${minutes}m ${seconds}s`;
}

export const getTime = (time) => {
  return Math.floor(time / 60) + ':' + ('0' + Math.floor(time % 60)).slice(-2);
};

export function computeFullName(profile) {
  const { fullName, firstName, lastName } = profile || {};
  if (firstName) {
    if (lastName) {
      return `${firstName} ${lastName}`;
    }

    return firstName;
  }

  return fullName;
}

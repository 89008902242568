import React from 'react';

import { AppStateProvider, UnsupportedBrowserWarning } from '@jaksmok/lovelanguage-common';
import { CssBaseline } from '@material-ui/core';
import { MuiThemeProvider } from '@material-ui/core/styles';
import ForgetPasswordForm from 'components/Form/ForgetPasswordForm';
import ResetPasswordForm from 'components/Form/ResetPasswordForm';
import SignInForm from 'components/Form/SignInForm';
import SignupForm from 'components/Form/SignUpForm';
import UpdateProfileForm from 'components/Form/UpdateProfileForm';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router, Redirect, Route, Switch } from 'react-router-dom';

import App from './App';
import VideoApp from './App.Twilio';
import PrivateRoute from './components/PrivateRoute/PrivateRoute';
import VideoMailApp from './components/VoiceMail/VideoMailApp';
import theme from './theme';

import './styles/show_more.css';
import './index.css';
import './fonts/campton-book.otf';
import './fonts/campton-book.otf';

ReactDOM.render(
  <MuiThemeProvider theme={theme}>
    <CssBaseline />
    <UnsupportedBrowserWarning>
      <Router>
        <AppStateProvider>
          <Switch>
            <PrivateRoute exact path="/">
              <App />
            </PrivateRoute>
            <PrivateRoute path="/room/:roomId">
              <VideoApp />
            </PrivateRoute>
            <PrivateRoute path="/voicemail/:roomId">
              <VideoMailApp />
            </PrivateRoute>
            <Route exact path="/sign-in">
              <SignInForm />
            </Route>
            <Route exact path="/sign-up">
              <SignupForm />
            </Route>
            <Route exact path="/forget-password">
              <ForgetPasswordForm />
            </Route>
            <Route exact path="/reset-password">
              <ResetPasswordForm />
            </Route>
            <Route exact path="/update-profile">
              <UpdateProfileForm />
            </Route>
            <Redirect to="/" />
          </Switch>
        </AppStateProvider>
      </Router>
    </UnsupportedBrowserWarning>
  </MuiThemeProvider>,
  document.getElementById('root')
);

import { isValidPhoneNumber } from 'react-phone-number-input';
import * as yup from 'yup';
import yupPassword from 'yup-password';

yupPassword(yup);

const requiredMessage = 'This field is required';

const email = yup.string().email().required(requiredMessage);

const phoneNumber = yup
  .mixed()
  .test('IsValidPhone', 'This is not a valid phone number', (v = '') => isValidPhoneNumber(v));

const password = yup
  .string()
  .min(8, 'Password must be at least 8 characters')
  .minUppercase(1, 'Password must be at least 1 uppercase letter')
  .minNumbers(1, 'Password must be at least 1 number')
  .required(requiredMessage);

export const signInSchema = yup.object({
  email,
  password: yup.string().required(requiredMessage),
});

export const signUpSchema = yup.object({
  firstName: yup.string().required(requiredMessage),
  lastName: yup.string().required(requiredMessage),
  phoneNumbers: phoneNumber,
  email,
  password,
  confirmPassword: yup
    .string()
    .oneOf([yup.ref('password'), null], 'Password does not matched')
    .required(requiredMessage),
});

export const changePasswordSchema = yup.object({
  currentPassword: yup.string().required(requiredMessage),
  newPassword: password,
  confirmPassword: yup
    .string()
    .oneOf([yup.ref('newPassword'), null], 'Password do not matched')
    .required(requiredMessage),
});

import React, { useRef, useState } from 'react';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import { makeStyles } from '@material-ui/core/styles';
import Button from 'components/Buttons/LLButton';
import Cropper from 'react-easy-crop';
import getCroppedImg from 'utils/cropImage';

const useStyles = makeStyles((theme) => ({
  dialog: {
    height: '100%',
    width: '100%',
    background: '#ffffff',
  },
  uploadImageWrapper: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  uploadImage: {
    cursor: 'pointer',
    borderRadius: '50%',
    width: '124px',
    height: '124px',
  },
  saveButton: {
    background: theme.palette.background.green,
    color: theme.palette.label.white,
    fontWeight: 600,
    '&:hover': {
      background: theme.palette.background.green,
      opacity: 0.8,
    },
    width: 100,
  },
}));

export default function UploadImage({ children, image, setImage, onSave, currentProfileImage }) {
  const inputRef = useRef();
  const classes = useStyles();
  const triggerFileSelectPopup = () => {
    inputRef.current.value = null;
    inputRef.current.click();
  };

  const [croppedArea, setCroppedArea] = useState(null);
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [open, setOpen] = useState(false);

  const handleClose = () => {
    setImage(currentProfileImage);
    setZoom(1);
    setCrop({ x: 0, y: 0 });
    setOpen(false);
  };

  const onCropComplete = (croppedAreaPercentage, croppedAreaPixels) => {
    setCroppedArea(croppedAreaPixels);
  };

  const onSelectFile = (event) => {
    if (event.target.files && event.target.files.length > 0) {
      const file = event.target.files[0];
      setImage(URL.createObjectURL(file));
      setOpen(true);
    }
  };

  const onCancel = () => {
    handleClose();
  };

  const handleSave = async () => {
    const croppedImage = await getCroppedImg(image, croppedArea);
    onSave(croppedImage);
    handleClose();
  };

  return (
    <div>
      {image ? (
        <>
          <Dialog
            open={open}
            classes={{ paper: classes.dialog }}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            onClose={handleClose}
          >
            <DialogContent>
              <Cropper
                image={image}
                crop={crop}
                zoom={zoom}
                aspect={1}
                cropShape="round"
                showGrid={false}
                onCropChange={setCrop}
                onZoomChange={setZoom}
                onCropComplete={onCropComplete}
              />
            </DialogContent>
            <DialogActions>
              <Button disableRipple style={{ width: 100 }} variant="contained" color="secondary" onClick={onCancel}>
                Cancel
              </Button>
              <Button disableRipple variant="contained" className={classes.saveButton} onClick={handleSave}>
                Save
              </Button>
            </DialogActions>
          </Dialog>
        </>
      ) : null}

      <div className={classes.uploadImageWrapper}>
        <input ref={inputRef} type="file" accept="image/*" style={{ display: 'none' }} onChange={onSelectFile} />
        {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
        <div className={classes.uploadImage} onClick={triggerFileSelectPopup}>
          {children}
        </div>
      </div>
    </div>
  );
}

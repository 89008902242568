import React, { useState } from 'react';

import { yupResolver } from '@hookform/resolvers/yup';
import { PhoneInput, useAppState, useAsyncCallback } from '@jaksmok/lovelanguage-common';
import { Box, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Alert from 'components/Alert/LLAlert';
import Button from 'components/Buttons/LLButton';
import Input from 'components/Input/Input';
import Password from 'components/Input/Password';
import LoginPageLayout from 'components/Layout/LoginPageLayout';
import { useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import * as yup from 'yup';
import YupPassword from 'yup-password';
import Colors from './../../Color';
import clsx from 'clsx';
import { Link } from 'react-router-dom';

import { signUpSchema } from '../../utils/formSchema';

YupPassword(yup);

const useStyles = makeStyles((theme) => ({
  signUpRoot: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    margin: 'auto',
    [theme.breakpoints.down('lg')]: {
      paddingBottom: '50px',
    },
    minHeight: '100vh',
    '& .MuiSvgIcon-root': {
      fill: 'white',
    },
    '& .MuiInput-input': {
      '&::placeholder': {
        color: 'white',
      },
      color: 'white', // if you also want to change the color of the input, this is the prop you'd use
    },
    '& .MuiInputBase-root': {
      color: 'white',
      borderRadius: '8px',
      background: 'none',
      fontFamily: 'campton-medium',
    },
    '&. .MuiOutlinedInput-root': {
      border: 'none',
    },
    '& .MuiOutlinedInput-notchedOutline': {
      border: 'none',
      background: 'rgba(255, 255, 255, 0.05)',
    },
    '& .MuiFormHelperText-root': {
      fontFamily: 'campton-book',
    },
  },
  signUpButton: {
    borderRadius: 8,
    padding: 10,
    background: Colors.secondary,
    fontFamily: 'campton-medium',
    fontWeight: 'bold',
    fontSize: 16,
    '&:hover': {
      background: Colors.secondary,
      opacity: 0.5,
    },
  },
  footerTextWrapper: {
    display: 'flex',
    justifyContent: 'center',
    gap: '5px',
    marginTop: theme.spacing(4),
  },
  footerLabel: {
    fontWeight: 500,
    color: 'white',
  },
  signUpLink: {
    color: Colors.green,
    fontWeight: '600',
    fontSize: '15px',
    textDecoration: 'none',
  },
}));

export default function SignUp() {
  const classes = useStyles();
  const { control, handleSubmit, formState } = useForm({ resolver: yupResolver(signUpSchema) });
  const { signUp } = useAppState();
  const history = useHistory();
  const [authError, setAuthError] = useState(null);

  const [handleSignUp] = useAsyncCallback(
    async ({ firstName, lastName, email, password, phoneNumbers }) => {
      setAuthError(null);
      try {
        await signUp(firstName, lastName, email, password, phoneNumbers);
        history.replace({ pathname: '/' });
      } catch (error) {
        setAuthError(error);
      }
    },
    [history, signUp]
  );

  return (
    <LoginPageLayout
      isFullScreen
      title={
        <>
          Sign up to 360Live <br />
          as an interpreter
        </>
      }
      footer={
        <div className={classes.footerTextWrapper}>
          <Box component="span" fontSize="16px" className={classes.footerLabel}>
            Already have an account?
          </Box>
          <Link to="/sign-in" className={clsx(classes.signUpLink, classes.linkLabel)}>
            Sign in
          </Link>
        </div>
      }
      classNames={classes.signUpRoot}
    >
      <form onSubmit={handleSubmit(handleSignUp)}>
        <Grid container>
          <Box item component={Grid} paddingY={1} xs={12}>
            {authError && (
              <Box mb={2}>
                <Alert type="error" style={{ width: '100%', height: 'auto', borderRadius: 8 }}>
                  {authError.message}
                </Alert>
              </Box>
            )}

            <Input
              fullWidth
              defaultValue=""
              name="firstName"
              variant="outlined"
              control={control}
              error={!!formState.errors?.firstName}
              helperText={formState.errors?.firstName?.message || null}
              placeholder="First name"
              type="text"
            />
          </Box>

          <Box item component={Grid} paddingY={1} xs={12}>
            <Input
              fullWidth
              defaultValue=""
              name="lastName"
              variant="outlined"
              control={control}
              error={!!formState.errors?.lastName}
              helperText={formState.errors?.lastName?.message || null}
              placeholder="Last name"
              type="text"
            />
          </Box>

          <Box item component={Grid} paddingY={1} xs={12}>
            <PhoneInput
              fullWidth
              defaultValue=""
              name="phoneNumbers"
              control={control}
              variant="outlined"
              error={!!formState.errors?.phoneNumbers}
              helperText={formState.errors?.phoneNumbers?.message || null}
              placeholder="Phone number"
            />
          </Box>

          <Box item component={Grid} paddingY={1} xs={12}>
            <Input
              fullWidth
              defaultValue=""
              name="email"
              variant="outlined"
              control={control}
              error={!!formState.errors?.email}
              helperText={formState.errors?.email?.message || null}
              placeholder="Email address"
              type="text"
            />
          </Box>

          <Box item component={Grid} paddingY={1} xs={12}>
            <Password
              fullWidth
              variant="outlined"
              name="password"
              type="password"
              defaultValue=""
              error={!!formState.errors?.password}
              helperText={formState.errors?.password?.message || null}
              control={control}
              placeholder="Password"
              inputProps={{ autoComplete: 'new-password', spellcheck: false }}
            />
          </Box>

          <Box item component={Grid} paddingY={1} xs={12}>
            <Password
              fullWidth
              variant="outlined"
              name="confirmPassword"
              type="password"
              defaultValue=""
              error={!!formState.errors?.confirmPassword}
              helperText={formState.errors?.confirmPassword?.message || null}
              control={control}
              placeholder="Confirm Password"
              inputProps={{ autoComplete: 'new-password', spellcheck: false }}
            />
          </Box>

          <Grid item xs={12}>
            <Box display="flex" justifyContent="space-around" width="100%" marginY={2}>
              <Button
                fullWidth
                variant="contained"
                color="primary"
                type="submit"
                loading={formState.isSubmitting}
                disabled={formState.isSubmitting}
                className={classes.signUpButton}
              >
                Sign Up
              </Button>
            </Box>
          </Grid>
        </Grid>
      </form>
    </LoginPageLayout>
  );
}

import React from 'react';

import { Box, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import logo from 'assets/Logo/360Live-192.png';
import bgImage from 'assets/bg.svg';
import clsx from 'clsx';
import Colors from './../../Color';

const useStyles = makeStyles((theme) => ({
  root: {
    background: `${Colors.blue}`,
    backgroundImage: `url(${bgImage})`,
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    backgroundSize: '230%',
    fontFamily: 'campton-book',
  },
  fullScreen: {
    minHeight: '100vh',
    width: '100%',
  },
  center: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  logo: {
    textAlign: 'center',
    width: '126px',
  },
  paper: {
    height: 'auto',
    width: '420px',
    borderRadius: '12px',
    boxShadow: theme.palette.shadow.main,
    background: 'rgba(255, 255, 255, 0.05)',
    padding: theme.spacing(4),
  },
  title: {
    fontWeight: '600',
    fontSize: '24px',
    color: Colors.primary,
    fontFamily: 'campton-medium',
    textAlign: 'center',
  },
}));

export default function LoginPageLayout({ fullScreen, title, children, footer, classNames, ...props }) {
  const classes = useStyles();
  return (
    <section
      className={clsx(classes.root, classes.center, classNames, { [classes.fullScreen]: fullScreen })}
      {...props}
    >
      <Box paddingTop="20px" paddingBottom="20px">
        <img src={logo} alt="360Live logo" className={classes.logo} />
      </Box>
      <div className={clsx(classes.paper, classes.center)}>
        <Box mb={2} display="flex" style={{ margin: 'auto' }}>
          <Typography className={classes.title}>{title}</Typography>
        </Box>
        {children}
        {footer}
      </div>
    </section>
  );
}

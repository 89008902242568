import React, { useCallback, useEffect, useState } from 'react';

import { useAppState, useSessionStorage } from '@jaksmok/lovelanguage-common';
import firebaseApp from '@jaksmok/lovelanguage-common/dist/utils/firebase';
import Box from '@material-ui/core/Box';
import CircularProgress from '@material-ui/core/CircularProgress';
import { makeStyles } from '@material-ui/core/styles';
import AddIcon from '@material-ui/icons/Add';
import Header from 'components/Header';
import UploadImage from 'components/UploadImage';
import { useHistory } from 'react-router-dom';
import { INTERPRETER_BREAK_DATE_KEY } from './../../constants';

import { uploadFile } from '../../services/fileUploadServices';
import UserService from '../../services/user';
import { blobUrlToFile, getInitials, isBlobUrl } from '../../utils/helpers';
import Button from '../Buttons/LLButton';
import LLDialog from '../Dialog/Dialog';
import ChangePasswordForm from './ChangePasswordForm';
import Colors from 'Color';
import clsx from 'clsx';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    height: `calc(100vh - ${theme.headerHeight}px)`,
    background: '#ffffff',
  },
  container: {
    height: '100vh',
    width: '350px',
  },
  userProfileImage: {
    borderRadius: '50%',
    width: '124px',
    height: '124px',
  },
  userFullName: {
    fontSize: '24px',
    fontWeight: '600',
    color: theme.palette.primary.dark,
    margin: '12px 0 4px 0',
    fontFamily: 'campton-medium',
  },
  infoLabel: {
    fontSize: '14px',
    color: theme.palette.primary.dark,
    opacity: 0.7,
    margin: '5px 0',
    fontFamily: 'campton-book',
  },
  badge: {
    position: 'absolute',
    height: '22px',
    width: '22px',
    borderRadius: '50%',
    background: theme.palette.background.green,
    margin: '-1.5rem 0px 0px 5rem',
    color: 'white',
    textAlign: 'center',
  },
  actionButton: {
    width: 250,
    fontSize: 16,
    fontWeight: 600,
  },
  actionButtonContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
  },
  changePasswordButton: {
    background: Colors.primary,
    '&:hover': {
      background: Colors.primary,
      opacity: 0.8,
    },
  },
  signOutButton: {
    color: 'white',
    background: Colors.red,
    '&:hover': {
      background: Colors.red,
      opacity: 0.8,
    },
  },
}));

export default function UpdateProfileForm() {
  const classes = useStyles();
  const { user, profile, signOut, setMeetingReturn } = useAppState();
  const history = useHistory();
  const [openChangePasswordDialog, setOpenChangePasswordDialog] = useState(false);

  const [userInfo, setUserInfo] = useState({ id: '', fullName: '', email: '' });
  const [profileImage, setProfileImage] = useState(null);
  const [profileChangeLoading, setProfileChangeLoading] = useState(false);
  const [passwordChangeLoading, setPasswordChangeLoading] = useState(false);
  const [, setBreakEndDate] = useSessionStorage(INTERPRETER_BREAK_DATE_KEY, null);

  useEffect(() => {
    (async () => {
      setProfileChangeLoading(true);
      setPasswordChangeLoading(true);
      if (user) {
        setUserInfo({ id: user.uid, fullName: user.displayName, email: user.email });
        setProfileChangeLoading(false);
        setPasswordChangeLoading(false);
        setProfileImage(user.photoURL);
      }
    })();
  }, [user]);

  const handleOpenChangePasswordDialog = useCallback(() => {
    setOpenChangePasswordDialog(true);
  }, []);

  const handleCloseChangePasswordDialog = useCallback((_, reason) => {
    if (reason === 'backdropClick') {
      return;
    }
    setOpenChangePasswordDialog(false);
  }, []);

  const handleSignOut = useCallback(async () => {
    await signOut();
    setBreakEndDate(null);
    setMeetingReturn(false);
    history.push('/');
  }, [history, signOut, setBreakEndDate, setMeetingReturn]);

  const handleSaveImage = async (profileImage) => {
    if (isBlobUrl(profileImage)) {
      setProfileChangeLoading(true);
      const file = await blobUrlToFile(profileImage, user.displayName);
      const { data: photoURL } = await uploadFile(file);
      await firebaseApp.auth().currentUser.updateProfile({ photoURL });
      const { data: currentProfile } = await UserService.getProfile();
      await UserService.updateProfile({ ...currentProfile, profileUrl: photoURL });
      setProfileImage(photoURL);
      setProfileChangeLoading(false);
    }
  };

  return (
    <section className={classes.root}>
      <Header />
      <Box paddingY="27px" />

      {profileChangeLoading && passwordChangeLoading ? (
        <Box width="100%" height="100vh" textAlign="center" lineHeight="80vh">
          <CircularProgress />
        </Box>
      ) : (
        <div className={classes.container}>
          <Box display="flex" flexDirection="column" textAlign="center">
            <UploadImage
              image={profileImage}
              setImage={setProfileImage}
              currentProfileImage={user?.photoURL || getInitials(userInfo?.fullName)}
              onSave={handleSaveImage}
            >
              {!profileImage ? (
                <Box
                  bgcolor="primary.main"
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  fontSize="4rem"
                  color="common.white"
                  className={classes.userProfileImage}
                >
                  {getInitials(userInfo.fullName)}
                </Box>
              ) : (
                <>
                  <img
                    src={profileImage}
                    alt="profile"
                    className={classes.userProfileImage}
                    style={{ opacity: profileChangeLoading ? 0.5 : 1 }}
                  />
                  {profileChangeLoading && (
                    <Box component="span" position="absolute" marginTop="45px" marginLeft="-80px">
                      <CircularProgress size="30px" />
                    </Box>
                  )}
                </>
              )}
              <div className={classes.badge}>
                <AddIcon />
              </div>
            </UploadImage>
            <Box className={classes.userFullName}>{userInfo.fullName}</Box>
            <Box className={classes.infoLabel}>Interpreter ID: {profile?.interpreterId}</Box>
            <Box className={classes.infoLabel}>Email: {userInfo.email}</Box>

            <Box my={2} />

            <Box className={classes.actionButtonContainer}>
              <Button
                className={clsx(classes.actionButton, classes.changePasswordButton)}
                color="primary"
                variant="contained"
                onClick={handleOpenChangePasswordDialog}
              >
                Change Password
              </Button>
              <Box my={0.7} />
              <Button
                className={clsx(classes.actionButton, classes.signOutButton)}
                variant="contained"
                onClick={handleSignOut}
              >
                Sign out
              </Button>
            </Box>
          </Box>
        </div>
      )}

      <ChangePasswordDialog open={openChangePasswordDialog} onClose={handleCloseChangePasswordDialog} />
    </section>
  );
}

function ChangePasswordDialog({ open, onClose }) {
  return (
    <LLDialog open={open} title="Change Password" handleClose={onClose}>
      <ChangePasswordForm onSuccess={onClose} />
    </LLDialog>
  );
}
